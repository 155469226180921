<template>
	<div class="w-full">
		<DecsisionAS />
		<DecsisionSP />
	</div>
</template>

<script>
import DecsisionAS from './DecsisionAS.vue';
import DecsisionSP from './DecsisionSP.vue';

export default {
	name: 'DecisionsPublicCouncil',
	components: {
		DecsisionAS,
		DecsisionSP
	}
};
</script>
