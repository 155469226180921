<template>
	<div class="w-full relative">
		<h1 class="text-xl mb-8 font-extrabold sm:text-2xl">Решения Общественного совета</h1>
		<div class="flex justify-between items-center">
			<h2 class="font-extrabold sm:text-xl">Для амбулаторных и стационарных условий</h2>
			<div class="flex items-center">
				<Button
					v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
					text="Отправить данные и закрыть редактирование"
					@click.native="blockUnBlockDecision()"
					class="mr-2"
				/>
				<Button v-else-if="decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee'])" text="Разблокировать" @click.native="blockUnBlockDecision()" class="mr-2" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcel(params)" :disabled="loadingExcel" :load="loadingExcel" />
			</div>
		</div>
		<div class="table-respons t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter" style="height: 600px">
			<table :style="decsisionAS.length > 0 ? 'min-height: 550px' : ''">
				<thead>
					<tr class="tr1__sticky">
						<th rowspan="3" style="min-width: 50px; padding: 0; text-align: center">П/П</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<p @click="switchFilterVisibility(0)">
									<span class="cursor-pointer underline"> Наименование МО </span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[0].fieldName)" icon-name="filter" class="hidden sm:block" />
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									position="left"
									:id-filter="0"
									:param="params"
									@query="getData"
									:fields="fields"
									:item="fields[0]"
								/>
							</div>
						</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<p @click="switchFilterVisibility(1)">
									<span class="cursor-pointer underline"> № протокола </span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[1].fieldName)" icon-name="filter" class="hidden sm:block" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="1" :param="params" @query="getData" :fields="fields" :item="fields[1]" />
						</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<p @click="switchFilterVisibility(2)">
									<span class="cursor-pointer underline"> Дата протокола </span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[2].fieldName)" icon-name="filter" class="hidden sm:block" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th colspan="4">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr class="tr2__sticky">
						<th colspan="2"><p>Открытость и доступность информации об организации</p></th>
						<th colspan="2"><p>Доступность услуг для инвалидов</p></th>
					</tr>
					<tr class="tr3__sticky">
						<th>
							<div class="flex">
								<p @click="switchFilterVisibility(3)">
									<span class="cursor-pointer underline">1.1</span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[3].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th>
							<div class="flex">
								<p @click="switchFilterVisibility(4)">
									<span class="cursor-pointer underline">1.2</span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[4].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="4" :param="params" @query="getData" :fields="fields" :item="fields[4]" />
						</th>
						<th>
							<div class="flex">
								<p @click="switchFilterVisibility(5)">
									<span class="cursor-pointer underline">3.1</span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[5].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="5" :param="params" @query="getData" :fields="fields" :item="fields[5]" />
						</th>
						<th>
							<div class="flex">
								<p @click="switchFilterVisibility(6)">
									<span class="cursor-pointer underline">3.2</span>
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[6].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="6" :param="params" @query="getData" :fields="fields" :item="fields[6]" />
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, index) in decsisionAS" :key="index">
					<tr @click="openRow(item)" :class="{ 'bg-gray-30': item.id === opened, 'cursor-pointer': item.hasBranch }">
						<td style="padding: 10px; text-align: center">
							<span v-if="params.currentPage === 0">
								{{ index + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (index + 1) }}
							</span>
						</td>
						<td>
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<div class="flex" v-if="!item.update">
								<Icons
									v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
									class="mr-2 icon-control cursor-pointer"
									icon-name="pen"
									@click.native="openUpdate(item)"
								/>
								<p :class="{ underline: item.hasBranch }">{{ item.medicalOrgName }}</p>
							</div>
							<div class="flex" v-else>
								<Icons class="mr icon-control cursor-pointer" icon-name="square-ok" @click.native="save(item)" />
								<Icons class="mr-2 icon-control cursor-pointer" icon-name="square-close" @click.native="closeUpdate(item, true)" />
								<p>{{ item.medicalOrgName }}</p>
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.protokolNumber }}</p>
							</div>
							<div v-else>
								<TextInput type="number" :error="protocol" v-model="item.protokolNumber" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.protokolDate === '---' ? '---' : item.protokolDate | moment('DD.MM.YYYY') }}</p>
							</div>
							<div v-else>
								<TextInput type="date" :min="minProtocolDate" :max="currentDate" v-model="item.protokolDate" :error="errorProtocolDate" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value1_1 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value1_1')" type="number" min="0" max="100" step="1" v-model.number="item.value1_1" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value1_2 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value1_2')" type="number" min="0" max="100" step="1" v-model.number="item.value1_2" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value3_1 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value3_1')" type="number" min="0" max="100" step="1" v-model.number="item.value3_1" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value3_2 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value3_2')" type="number" min="0" max="100" step="1" v-model.number="item.value3_2" />
							</div>
						</td>
					</tr>
					<template v-if="item.id === opened">
						<tr v-for="(subItem, ind1) in item.branches" :key="subItem.id" :class="{ 'bg-gray-30': item.id === opened }">
							<td style="padding: 10px; text-align: center">
								{{ ind1 + 1 }}
							</td>
							<td>
								<div class="flex items-center mb-2">
									<div v-if="subItem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="subItem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<div class="flex" v-if="!subItem.update">
									<Icons
										v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
										class="mr-2 icon-control cursor-pointer"
										icon-name="pen"
										@click.native="openUpdate(subItem)"
									/>
									<p>{{ subItem.medicalOrgName }}</p>
								</div>
								<div class="flex" v-else>
									<Icons class="mr icon-control cursor-pointer" icon-name="square-ok" @click.native="save(subItem)" />
									<Icons class="mr-2 icon-control cursor-pointer" icon-name="square-close" @click.native="closeUpdate(subItem, true)" />
									<p>{{ subItem.medicalOrgName }}</p>
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.protokolNumber }}</p>
								</div>
								<div v-else>
									<TextInput type="number" :error="protocol" v-model="subItem.protokolNumber" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.protokolDate === '---' ? '---' : subItem.protokolDate | moment('DD.MM.YYYY') }}</p>
								</div>
								<div v-else>
									<TextInput type="date" :min="minProtocolDate" :max="currentDate" v-model="subItem.protokolDate" :error="errorProtocolDate" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value1_1 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(subItem, 'value1_1')" type="number" min="0" max="100" step="1" v-model.number="subItem.value1_1" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value1_2 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(subItem, 'value1_2')" type="number" min="0" max="100" step="1" v-model.number="subItem.value1_2" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value3_1 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(subItem, 'value3_1')" type="number" min="0" max="100" step="1" v-model.number="subItem.value3_1" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value3_2 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(subItem, 'value3_2')" type="number" min="0" max="100" step="1" v-model.number="subItem.value3_2" />
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<div v-if="!loadingTable && decsisionAS.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import TextInput from '@/components/TextInput.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import loadingComponent from '@/components/Loading.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import actionsLocal from '../store/actions';

export default {
	name: 'DecisionsPublicCouncil',
	components: {
		Button,
		loadingComponent,
		TextInput,
		Icons,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			currentDate: '',
			minProtocolDate: '',
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			decisionBlock: {
				type: 1,
				year: 2022,
				flag: false,
				regionId: null
			},
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'medicalOrgName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'medicalOrgName',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '№ протокола',
					fieldName: 'protokolNumber',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Дата протокола',
					fieldName: 'protokolDate',
					filterType: 10,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '1.1',
					fieldName: 'value1_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '1.2',
					fieldName: 'value1_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '3.1',
					fieldName: 'value3_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '3.2',
					fieldName: 'value3_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0,
				columns: []
			},
			page: 0,
			allRows: [],
			decsisionAS: [],
			openedItem: null,
			savedItem: {},
			loadingExcel: false,
			loadingTable: false,
			protocol: null,
			errorProtocolDate: null,
			opened: null
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		clearNumbers(item, param) {
			if (!Number.isInteger(item[param])) {
				item[param] = 0;
			} else if (item[param] >= 100) {
				item[param] = 100;
			} else if (item[param] <= 0) {
				item[param] = 0;
			}
		},
		blockUnBlockDecision() {
			let message = this.decisionBlock.flag ? 'Вы уверены, что хотите открыть редактирование?' : 'Вы уверены, что хотите закрыть редактирование?';

			if (confirm(message)) {
				this.decisionBlock.flag = !this.decisionBlock.flag;
				this.blockUnBlockFullDecision(this.decisionBlock);
			}
		},
		openUpdate(item) {
			if (this.openedItem !== null) {
				this.closeUpdate(this.openedItem, true);
			}
			this.openedItem = item;
			Object.assign(this.savedItem, item);
			this.$set(item, 'update', true);
			this.updateItem(item);
		},
		closeUpdate(item, restore) {
			if (restore && item.medicalOrgId === this.savedItem.medicalOrgId) {
				Object.assign(item, this.savedItem);
			}
			this.$set(item, 'update', false);
			this.updateItem(item);
			this.openedItem = null;
			this.savedItem = {};
		},
		updateItem(item) {
			if (item.parentId === null) {
				let index = this.decsisionAS.findIndex((x) => x.id === item.id);
				this.decsisionAS.splice(index, 1, item);
			} else {
				let parent = this.decsisionAS.find((x) => x.medicalOrgId === item.parentId);
				let index = parent.branches.findIndex((x) => x.id === item.id);
				parent.branches.splice(index, 1, item);
			}
		},
		save(item) {
			if (!item.protokolNumber) {
				this.protocol = 'Обязательное поле';
			} else if (!this.protocolDateIsValid(item)) {
				this.errorProtocolDate = 'Неверная дата';
			} else {
				this.updateOSDecsisionAS(item).then(() => {
					this.closeUpdate(item, false);
					this.protocol = null;
					this.errorProtocolDate = null;
				});
			}
		},
		protocolDateIsValid(item) {
			let protocolDate = this.$moment(item.protokolDate, 'YYYY-MM-DD');

			if (this.$moment(this.minProtocolDate, 'YYYY-MM-DD') <= protocolDate && protocolDate <= this.$moment(this.currentDate, 'YYYY-MM-DD')) {
				return true;
			}

			return false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcel(params) {
			this.loadingExcel = true;

			this.$http
				.post(`OS/GetOSDecsisionASExcel?regionId=${this.region.id}`, params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Амбулаторные и стационарные.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadingExcel = false;
				})
				.catch((error) => {
					if (error) {
						this.loadingExcel = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getOSDecsisionAS(this.params, this.region.id).then((res) => {
					this.allRows = res.data.data;
					this.decsisionAS = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;

					this.allRows.map((item) => {
						this.initializeItem(item);
						item.branches.forEach((x) => this.initializeItem(x));
						return item;
					});

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.openedItem = null;
					this.savedItem = {};
					this.loadingTable = false;
				});
			} else {
				this.decsisionAS = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		initializeItem(item) {
			item.update = false;
			item.protokolDate = item.protokolDate ? this.$moment(item.protokolDate).format('YYYY-MM-DD') : '---';
		}
	},
	mounted() {
		this.currentDate = this.$moment().format('YYYY-MM-DD');
		this.minProtocolDate = this.$moment().subtract(3, 'months').format('YYYY-MM-DD');

		this.params.filters[0].value1 = this.region.id;

		this.decisionBlock.regionId = this.region.id;

		this.getDecisionBlockStatus(this.decisionBlock).then((res) => {
			this.decisionBlock.flag = res.data.settingValue === 'true';
		});

		this.getRegionYearData(this.region.id).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	height: 101px;
	top: 0;
	position: sticky;
	z-index: 7;
}
.tr2__sticky {
	background-color: #f9fafb;
	height: 81px;
	top: 101px;
	position: sticky;
	z-index: 6;
}
.tr3__sticky {
	background-color: #f9fafb;
	height: 61px;
	top: 182px;
	position: sticky;
	z-index: 6;
}

.table-respons td,
.table-respons th {
	padding: 20px 30px;
	vertical-align: center;
	text-align: left;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 385px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons .th-sort {
	/* text-decoration-line: underline;
	cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	/* box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4); */
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
